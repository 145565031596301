export const BASE_URL = window.msalConfig.apiurl + "/api/V1/";
export const clientId = "497cd87a-b06b-4e64-9208-38ca3648b38b";
export const tenantId = "e0793d39-0939-496d-b129-198edd916feb";
export const scopes = ['86e99bfa-1e7c-44bf-930b-c9be2bb5345b/hub_access.read'];
export const pbiScopes = ['https://analysis.windows.net/powerbi/api/Report.Read.All'];
export const SVM_URL = "https://synopsvaluemeter.accenture.com/api/"
export const WRAPPER_BASE_URL = window.msalConfig.wrapperUrl;

export const REDIRECT_URL=window.msalConfig.appurl;

export const MAC_ID = "89D56DCC-1F98-11B2-A85C-D6C1758750DD";
export const USER = "User";
export const ADMIN = "Admin";
export const ENVIRONMENT = "uat";
export const ENTERPRISE_ID = "tejinder.a.singh"
export const APP_VERSION="Version 4.9.0";
